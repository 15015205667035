import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ImageCard>`}</inlineCode>{` component should generally be used inside of a
`}<inlineCode parentName="p">{`<Row className="image-card-group">`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` component. This allows us to
properly space the `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` components when they wrap on mobile.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Title" href="/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "702px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMFAQIE/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAGtxHGiaiu2vNgy/8QAGxABAQABBQAAAAAAAAAAAAAAAQIhABAREjH/2gAIAQEAAQUC7axTUgx5NcNUqYvb/8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQMBAT8BI//EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPwFY7P/EABkQAAIDAQAAAAAAAAAAAAAAAAARARAiMf/aAAgBAQAGPwJs1B22Rf8A/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERQSExEKGx/9oACAEBAAE/Ib9BjNAxrwJLpRLd6G5CRmaW+f/aAAwDAQACAAMAAAAQ7AgA/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARMf/aAAgBAwEBPxAFahi//8QAFxEAAwEAAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxCokW6//8QAHBABAQADAAMBAAAAAAAAAAAAAREAITEQQVGx/9oACAEBAAE/ECGpC6WssQEl97fuXDlZ1q5GMSF1vmO19VsscVI3o3imJKUecX9xa3x//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/6b70a834a883b666b3c5249b8014d5ba/16532/square.jpg",
              "srcSet": ["/static/6b70a834a883b666b3c5249b8014d5ba/69549/square.jpg 288w", "/static/6b70a834a883b666b3c5249b8014d5ba/473e3/square.jpg 576w", "/static/6b70a834a883b666b3c5249b8014d5ba/16532/square.jpg 702w"],
              "sizes": "(max-width: 702px) 100vw, 702px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
  <ImageCard title="Dark title" titleColor="dark" aspectRatio="1:1" href="/" actionIcon="arrowRight" subTitleColor="dark" subTitle="Dark subtitle" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAwb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAF3COscmdD/xAAZEAEBAQADAAAAAAAAAAAAAAABAAMCEhP/2gAIAQEAAQUCmW9Mx56jd7//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAkRASIUFC/9oACAEBAAY/Ap6ZizNs/8QAHRAAAgEEAwAAAAAAAAAAAAAAAAFREBEhMWGR0f/aAAgBAQABPyHihCZmIzPWI7nvsP/aAAwDAQACAAMAAAAQE8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEBAQACAwEAAAAAAAAAAAABEQAhUTFxwfD/2gAIAQEAAT8QDntLNAc5SOoAmAC/nu4cIwhU+6w/k97/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Light dark",
              "title": "Light dark",
              "src": "/static/a4517f9986d09b6a0d28aac5ca67d4ac/2e753/light-dark.jpg",
              "srcSet": ["/static/a4517f9986d09b6a0d28aac5ca67d4ac/69549/light-dark.jpg 288w", "/static/a4517f9986d09b6a0d28aac5ca67d4ac/473e3/light-dark.jpg 576w", "/static/a4517f9986d09b6a0d28aac5ca67d4ac/2e753/light-dark.jpg 1152w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Title" subTitle="Subtitle" aspectRatio="1:2" href="/" actionIcon="download" titleColor="light" subTitleColor="light" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "768px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAwQC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAFV09rKQGow8yqZLkoGP//EABwQAAICAgMAAAAAAAAAAAAAAAECAAMEERATIv/aAAgBAQABBQJUZ41brxUgFTqVmQB2MV0uT6vO7hYQA+jP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPwHFjlV//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8BuNEf/8QAHRAAAQUAAwEAAAAAAAAAAAAAAQACEBEhEiIxQf/aAAgBAQAGPwLqLWthrftXGZisejylxcE6LA2P/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITEQUWGR/9oACAEBAAE/IWMkbQrgOdwgGBYDsBT2PNgWW0uLbWrCpd/alAVxPr6xbbZ//9oADAMBAAIAAwAAABCXEzP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFB/9oACAEDAQE/EN8sHJparf/EABcRAQEBAQAAAAAAAAAAAAAAAAERAEH/2gAIAQIBAT8QjuGlxBhCb//EAB4QAQACAwADAQEAAAAAAAAAAAEAESExQVFhgRCh/9oACAEBAAE/EFBSbTR9gKlHTJ/PxQQRtU57L6w8C4HRao9LcxfHFdK1XT1L4xBYX7IZDgq+YhxgwEYZkS2c3zXmIiWraz//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Plane image",
              "title": "Plane image",
              "src": "/static/76c733b3cfce318d12ed56e88eb28d8a/28e3e/plane.jpg",
              "srcSet": ["/static/76c733b3cfce318d12ed56e88eb28d8a/69549/plane.jpg 288w", "/static/76c733b3cfce318d12ed56e88eb28d8a/473e3/plane.jpg 576w", "/static/76c733b3cfce318d12ed56e88eb28d8a/28e3e/plane.jpg 768w"],
              "sizes": "(max-width: 768px) 100vw, 768px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard aspectRatio="1:1" href="/" hoverColor="dark" mdxType="ImageCard">
          <img {...{
            "src": "/0121df80502c40da7042a2366698c5f0/color-grid.svg",
            "alt": "color pallete array"
          }}></img>
  </ImageCard>
      <ImageCard disabled title="Title" subTitle="Subtitle" aspectRatio="1:1" href="/" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHhS2MALBUH/8QAGBABAAMBAAAAAAAAAAAAAAAAAQMQESH/2gAIAQEAAQUCs6yGFatf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGBAAAgMAAAAAAAAAAAAAAAAAARAgIUH/2gAIAQEABj8Chrslf//EABwQAQACAQUAAAAAAAAAAAAAAAEAERAxQVFhsf/aAAgBAQABPyGOm2DScspPWBRsaYmpB24//9oADAMBAAIAAwAAABDwCAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAdEAEAAQQDAQAAAAAAAAAAAAABEQAQITFBUaFh/9oACAEBAAE/EDefaAT7R9soYqBikwoTGkeWJKDSMJXOCoRLf//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IBM Cloud Server",
              "title": "IBM Cloud Server",
              "src": "/static/8bec1dcb84a4d14a434d4ac6368b7b80/2e753/IBM_Cloud_Server.jpg",
              "srcSet": ["/static/8bec1dcb84a4d14a434d4ac6368b7b80/69549/IBM_Cloud_Server.jpg 288w", "/static/8bec1dcb84a4d14a434d4ac6368b7b80/473e3/IBM_Cloud_Server.jpg 576w", "/static/8bec1dcb84a4d14a434d4ac6368b7b80/2e753/IBM_Cloud_Server.jpg 1152w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ImageCard/ImageCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ImageCard",
        "path": "components/ImageCard/ImageCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ImageCard"
      }}>{`<Row className="image-card-group">
  <Column colMd={4} colLg={4} noGutterSm>
    <ImageCard title="Title" subTitle="Subtitle" href="/">
      
![Square](/images/square.jpg)

    </ImageCard>
    <ImageCard
      title="Dark title"
      titleColor="dark"
      aspectRatio="1:1"
      href="/"
      actionIcon="arrowRight"
      subTitleColor="dark"
      subTitle="Dark subtitle">

![Light dark](./images/light-dark.jpg)

    </ImageCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    <ImageCard
      title="Title"
      subTitle="Subtitle"
      aspectRatio="1:2"
      href="/"
      actionIcon="download"
      titleColor="light"
      subTitleColor="light">
      
![Plane image](./images/plane.jpg)

    </ImageCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    <ImageCard aspectRatio="1:1" href="/" hoverColor="dark">
      
![color pallete array](/images/color-grid.svg)

    </ImageCard>
    <ImageCard disabled aspectRatio="1:1" href="/">

![Square](/images/square.jpg)

    </ImageCard>

  </Column>
</Row>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background image for the card, make sure it is saved out at the correct aspect ratio or it will appear distorted`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aspectRatio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1:1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set card aspect ratio, default is `}<inlineCode parentName="td">{`1:1`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`1:1`}</inlineCode>{`, `}<inlineCode parentName="td">{`16:9`}</inlineCode>{`, `}<inlineCode parentName="td">{`4:3`}</inlineCode>{`, `}<inlineCode parentName="td">{`2:1`}</inlineCode>{`, `}<inlineCode parentName="td">{`1:2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Smaller title on top left of card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Large title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is no icon, options are `}<inlineCode parentName="td">{`Launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`ArrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`Download`}</inlineCode>{`, `}<inlineCode parentName="td">{`Disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`Email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set title text color, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` or `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitleColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set sub title text color, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` or `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set icon color, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` or `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`hoverColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set hover to lighten or darken the image, default is `}<inlineCode parentName="td">{`light`}</inlineCode>{`, options are `}<inlineCode parentName="td">{`light`}</inlineCode>{` or `}<inlineCode parentName="td">{`dark`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      